<template>
    <div class="header">
        <!-- <el-image
            :src="require('../../assets/logo.png')"
        >
        </el-image> -->
        <div class="logo">
            <span>C<span class="">A</span><span class="color">L</span><span class="color">P</span></span>
        </div>
        <div class="menu">
            <div class="item" :class="{'active':$route.name === 'page'}">
                <a href="/">หน้าแรก</a>
            </div>
            <div class="item " :class="{'active':$route.name === 'project'}">
                <el-dropdown @command="handleCommand" placement="top-start">
                    <span class="el-dropdown-link">
                        โครงการ<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="/project/1">โครงการทุนวัฒนธรรมชุมชนสู่หัตถกรรมล้านนาสร้างสรรค์</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div class="item" :class="{'active':$route.name === 'community'}">
                 <el-dropdown @command="handleCommand" placement="top-start">
                   <span class="el-dropdown-link">
                        ชุมชน<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="/community/1">ศูนย์วัฒนธรรมไทลื้อวัดหย่วน ตำบลหย่วน อำเภอเชียงคำ จังหวัดพะเยา</el-dropdown-item>
                         <el-dropdown-item command="/product/ng1">ชุมชนแม่อิง ตำบลแม่อิง อำเภอภูกามยาว จังหวัดพะเยา</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div class="item" :class="{'active':$route.name === 'product'}">
                 <el-dropdown @command="handleCommand" placement="top-start">
                   <span class="el-dropdown-link">
                        ผลิตภัณฑ์/ผลงาน<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="/product/cp1">กระเป๋าสะพายไทลื้อผ้าตาแสง</el-dropdown-item>
                        <el-dropdown-item command="/product/cp2">ถุงย่ามยกมุกไทลื้อ</el-dropdown-item>
                        <el-dropdown-item command="/product/cp3">โมเดลตุ๊กตาไทลื้อตั้งโต๊ะ</el-dropdown-item>
                        <el-dropdown-item command="/product/cp4">ถุงผ้าไทลื้อใส่แก้วเยติ</el-dropdown-item>
                        <el-dropdown-item command="/product/cp5">หมวกผ้าฝ้ายปักลายอัตลักษณ์ไทลื้อ</el-dropdown-item>
                        <el-dropdown-item command="/product/cp6">หมอนอิงไทลื้อ</el-dropdown-item>
                        <el-dropdown-item command="/product/ng1">การออกแบบชุดฟ้อนท์ตัวอักษร</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
    
    </div>
</template>

<script>
  export default {
    methods: {
      handleCommand(command) {
        this.$router.push(command)
      }
    }
  }
</script>

<style lang="scss" scoped>
    @import "../../styles/element-variables.scss";
    .header{
        display:flex;
        justify-content: space-between;
        padding: 10px 10vw;
        .el-image{
            height: 60px;
        }

        
        .menu{
            display:flex;
            align-items: center;
            .item{
                padding: 0 10px;
                    a,span{
                        color:$--color-text-primary;
                        text-decoration:none;
                        font-size: 16px;
                        &:hover{
                        color:$--color-primary;
                          
                    }
                }
                &.active{
                    a,span{
                        color:$--color-primary;
                          font-weight: 600;
                        
                    }
                }
              .el-dropdown-link{
                  cursor: pointer;
              }
            }

        }
        @media (max-width: 768px) {
   
            align-items: center;
            justify-content: center;
            .menu{
                display: none;
            }
            
            
        }
        
    }
</style>