<template>
  <el-container>
    <el-header>
        <Header/>
            <Carousel :carousel="[
      require(`../assets/carousel/1.jpg`),
      require(`../assets/carousel/2.jpg`),
      require(`../assets/carousel/3.jpg`),
      require(`../assets/carousel/4.jpg`),
      require(`../assets/carousel/5.jpg`)
    ]"/>
    </el-header>
    <el-main>
      <slot></slot>
    </el-main>
    <el-footer>
      <Footer/>
    </el-footer>
  </el-container>
</template>



<script>
import Header from '@/components/navMenu/Header'
import Carousel from '@/components/page/Carousel'
import Footer from '@/components/navMenu/Footer'


export default {
  components: {
    Header,
    Carousel,
    Footer
  }
}
</script>

<style lang="scss" scoped>
  .el-header{
    padding: 0;
    height: auto !important;
  }
  .el-main{
    min-height: 10vw;
    padding: 0;
  }
  .el-footer{
    padding: 0;
  }
</style>