<template>
  <div class="block">
    <el-carousel  :autoplay="true">
      <el-carousel-item v-for="item in carousel" :key="item" >
        <el-image
            :src="item"
            fit="cover"
        >
        </el-image>
      
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
   props:{
       carousel:{
           type:Array,
           default(){
             return []
           }
       }
   }
}
</script>

<style lang="scss" scoped>
    .el-image{
        width: inherit;
    }

</style>